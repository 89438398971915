<template>
  <div>
    <div>
      <h3>Documentos</h3>
    </div>
    <div>
      <hr />
    </div>

    <div class="d-flex flex-column flex-lg-row flex-fill gap-3">
      <div class="bg-light rounded flex-shrink-0 p-3" id="sidebar">
        <button
          class="btn btn-sidebar-toggle d-inline-flex align-items-center bg-transparent rounded shadow-none border-0 p-0"
          @click="toggleSidebar"
        >
          <i class="bi bi-list fs-4"></i>
          <span class="fw-semibold fs-4 ms-2">Filtros</span>
        </button>

        <div class="filter-list">
          <hr class="mt-3 mb-2" />

          <div class="mb-2">
            <span
              role="button"
              class="selector-buzon hover d-block rounded px-3 py-2"
              :class="{
                'bg-secondary text-light': parametros.buzon == 'Recibidos',
              }"
              @click="cambiarBuzon"
            >
              Recibidos
            </span>

            <span
              role="button"
              class="selector-buzon hover d-block rounded px-3 py-2"
              :class="{
                'bg-secondary text-light': parametros.buzon == 'Enviados',
              }"
              @click="cambiarBuzon"
            >
              Enviados
            </span>
          </div>

          <ul class="list-unstyled mx-2 mb-0">
            <li>
              <button
                class="btn btn-toggle d-inline-flex align-items-center bg-transparent rounded shadow-none border-0 px-0 py-1 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#fecha-collapse"
                aria-expanded="true"
              >
                <i class="bi bi-chevron-right"></i>
                <span class="fw-semibold ms-2">Fecha</span>
              </button>
              <div class="collapse show" id="fecha-collapse">
                <div class="btn-toggle-nav fw-normal mx-3 px-1 py-1 small">
                  <div v-show="!modelo.filtrar.fecha">
                    <span
                      role="button"
                      class="hover d-block link-dark rounded p-1"
                      v-for="(f, i) in datos.filtros.fecha"
                      :key="i"
                      @click="agregarFiltroFecha(f)"
                    >
                      {{ f.descripcion }}
                    </span>
                  </div>
                  <div v-show="modelo.filtrar.fecha">
                    <div class="d-inline-flex align-items-center p-1">
                      <span class="lh-sm fw-semibold me-1">
                        {{ modelo.fecha.descripcion }}
                      </span>
                      <i
                        role="button"
                        class="bi bi-x btn-quitar-filtro"
                        title="Quitar filtro"
                        @click="quitarFiltroFecha"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li
              v-if="
                !this.$store.getters.nombreDependencia ||
                parametros.buzon == 'Recibidos'
              "
            >
              <button
                class="btn btn-toggle d-inline-flex align-items-center bg-transparent rounded shadow-none border-0 px-0 py-1 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#origen-collapse"
                aria-expanded="false"
              >
                <i class="bi bi-chevron-right"></i>
                <span class="fw-semibold ms-2">Origen</span>
              </button>

              <div class="collapse" id="origen-collapse">
                <div class="btn-toggle-nav fw-normal mx-3 px-2 py-1 small">
                  <div v-if="parametros.buzon == 'Recibidos'">
                    <span>Organismo</span>
                    <div class="py-1">
                      <div v-show="!modelo.filtrar.origen.organismo">
                        <formgroup
                          :validator="$v.modelo.origen.organismo"
                          v-slot="scope"
                        >
                          <vue-searcher
                            id="buscadorOrganismo"
                            data-field-id="codigoOrganismo"
                            data-field-text="nombre"
                            page-size="10"
                            url-search="../../api/Organismos/Lista?Provincia=[Criterio]"
                            url-get="../../api/Organismos/"
                            url-search-key="[Criterio]"
                            list-property-name="lista"
                            object-property-name="organismo"
                            placeholder="Buscar organismo.."
                            :allow-pagging="true"
                            v-model="modelo.origen.organismo"
                            style="width: fit-content"
                            :ajax-settings="setAjaxSettings"
                            @on-item-selected="forceRerender(scope)"
                            @on-cleaning="limpiarDependenciaOrigen(scope)"
                            @keyup.enter="agregarFiltro('destino', 'organismo')"
                          ></vue-searcher>
                        </formgroup>
                      </div>
                      <div v-show="modelo.filtrar.origen.organismo">
                        <div class="label-filtro d-flex align-items-center">
                          <span class="lh-sm fw-semibold">{{
                            modelo.origen.organismo
                          }}</span>
                          <i
                            class="bi bi-x btn-quitar-filtro"
                            title="Quitar filtro"
                            @click="quitarFiltro('origen', 'organismo')"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <span>Dependencia</span>
                    <div class="py-1">
                      <div v-show="!modelo.filtrar.origen.dependencia">
                        <vue-searcher
                          ref="searcherDependenciaOrigen"
                          id="buscadorDependenciaOrigen"
                          data-field-id="codigoDependencia"
                          data-field-text="nombre"
                          page-size="10"
                          :url-search="getDependenciaOrigen"
                          url-get="../../api/Dependencias/ListaSearcher?CodigoOrganismo="
                          url-search-key="[Criterio]"
                          list-property-name="lista"
                          object-property-name="codigoOrganismo"
                          placeholder="Buscar dependencia.."
                          :allow-pagging="true"
                          v-model="modelo.origen.dependencia"
                          style="width: fit-content"
                          :key="datos.keyDependenciaOrigen"
                          :ajax-settings="setAjaxSettings"
                          @keyup.enter="agregarFiltro('origen', 'dependencia')"
                          @on-item-selected="obtener()"
                          @on-cleaning="obtener()"
                        ></vue-searcher>
                      </div>
                      <div v-show="modelo.filtrar.origen.dependencia">
                        <div class="label-filtro d-flex align-items-center">
                          <span class="lh-sm fw-semibold">{{
                            modelo.origen.dependencia
                          }}</span>
                          <i
                            class="bi bi-x btn-quitar-filtro"
                            title="Quitar filtro"
                            @click="quitarFiltro('origen', 'dependencia')"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li
              v-if="
                !this.$store.getters.nombreDependencia ||
                parametros.buzon == 'Enviados'
              "
            >
              <button
                class="btn btn-toggle d-inline-flex align-items-center bg-transparent rounded shadow-none border-0 px-0 py-1 collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#destino-collapse"
                aria-expanded="false"
              >
                <i class="bi bi-chevron-right"></i>
                <span class="fw-semibold ms-2">Destino</span>
              </button>

              <div class="collapse" id="destino-collapse">
                <div class="btn-toggle-nav fw-normal mx-3 px-2 py-1 small">
                  <div v-if="parametros.buzon == 'Enviados'">
                    <span>Organismo</span>
                    <div class="py-1">
                      <div v-show="!modelo.filtrar.destino.organismo">
                        <formgroup
                          :validator="$v.modelo.destino.organismo"
                          v-slot="scope"
                        >
                          <vue-searcher
                            id="buscadorOrganismo"
                            data-field-id="codigoOrganismo"
                            data-field-text="nombre"
                            page-size="10"
                            url-search="../../api/Organismos/Lista?Provincia=[Criterio]"
                            url-get="../../api/Organismos/"
                            url-search-key="[Criterio]"
                            list-property-name="lista"
                            object-property-name="organismo"
                            placeholder="Buscar organismo.."
                            :allow-pagging="true"
                            v-model="modelo.destino.organismo"
                            style="width: fit-content"
                            :ajax-settings="setAjaxSettings"
                            @keyup.enter="agregarFiltro('destino', 'organismo')"
                            @on-item-selected="forceRerender(scope)"
                            @on-cleaning="limpiarDependenciaDestino(scope)"
                          ></vue-searcher>
                        </formgroup>
                      </div>
                      <div v-show="modelo.filtrar.destino.organismo">
                        <div class="label-filtro d-flex align-items-center">
                          <span class="lh-sm fw-semibold">{{
                            modelo.destino.organismo
                          }}</span>
                          <i
                            class="bi bi-x btn-quitar-filtro"
                            title="Quitar filtro"
                            @click="quitarFiltro('destino', 'organismo')"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <span>Dependencia</span>
                    <div class="py-1">
                      <div v-show="!modelo.filtrar.destino.dependencia">
                        <vue-searcher
                          ref="searcherDependenciaDestino"
                          id="buscadorDependenciaDestino"
                          data-field-id="codigoDependencia"
                          data-field-text="nombre"
                          page-size="10"
                          :url-search="getDependenciaDestino"
                          url-get="../../api/Dependencias/ListaSearcher?CodigoOrganismo="
                          url-search-key="[Criterio]"
                          list-property-name="lista"
                          object-property-name="codigoOrganismo"
                          placeholder="Buscar dependencia.."
                          :allow-pagging="true"
                          v-model="modelo.destino.dependencia"
                          style="width: fit-content"
                          :ajax-settings="setAjaxSettings"
                          :key="datos.keyDependenciaDestino"
                          @keyup.enter="agregarFiltro('origen', 'dependencia')"
                          @on-item-selected="obtener()"
                          @on-cleaning="obtener()"
                        ></vue-searcher>
                      </div>
                      <div v-show="modelo.filtrar.destino.dependencia">
                        <div class="label-filtro d-flex align-items-center">
                          <span class="lh-sm fw-semibold">{{
                            modelo.destino.dependencia
                          }}</span>
                          <i
                            class="bi bi-x btn-quitar-filtro"
                            title="Quitar filtro"
                            @click="quitarFiltro('destino', 'dependencia')"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="flex-grow-1">
        <div class="rounded p-3 row">
          <span class="fs-4 fw-semibold col-11">{{ parametros.buzon }}</span>
          <div
            class="btn-toolbar gap-2 col-1"
            role="toolbar"
            aria-label="Barra de herramientas"
          >
            <button
              type="button"
              class="btn btn-sm btn-primary"
              title="Recargar"
              @click="obtener()"
            >
              <i class="bi bi-arrow-clockwise"></i>
            </button>
          </div>
        </div>

        <hr class="mt-0 mb-2" />

        <div class="text-center py-3" v-show="parametros.loading">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Cargando...</span>
          </div>
        </div>

        <div v-show="!parametros.loading">
          <div v-for="doc in datos.documentosEnProceso" :key="doc.id">
            <div
              role="button"
              class="d-flex flex-column rounded hover px-3 py-2 gap-2"
            >
              <div
                class="d-flex align-items-center justify-content-between fw-semibold"
              >
                <span class="text-muted">{{ doc.id }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between small"
              >
                <span
                  class="badge bg-primary"
                  v-if="doc.estadoOperacion == 'EN_PROCESO'"
                >
                  Pendiente
                </span>
              </div>
            </div>
          </div>
          <div v-for="d in datos.documentos" :key="d.id">
            <div
              role="button"
              class="d-flex flex-column rounded hover px-3 py-2 gap-2"
              @click="verDocumento(d)"
              
            >
              <div
                class="d-flex align-items-center justify-content-between fw-semibold"
              >
                <div class="d-inline-block w-50 d-flex align-items-center">
                  <span class="text-muted d-none">{{ d.id }}</span>
                 
                  <span
                    class="badge bg-primary"
                    v-if="parametros.buzon == 'Recibidos' && !d.recibido"
                  >
                    Nuevo
                  </span>
                  <span
                    class="badge bg-secondary"
                    v-if="d.tipoEnvio=='CON_ADJUNTOS'"
                  >
                    <i class="bi bi-paperclip"></i>
                  </span>
                  <span
                    class="badge bg-success"
                    v-if="parametros.buzon == 'Enviados' && d.recibido"
                  >
                    Recibido el
                    {{
                      (f = moment(d.recibido)
                        .format("D MMM [de] YYYY [a las] LT")
                        .replace(".", ""))
                    }}
                  </span>
                  <span
                    class="badge bg-danger"
                    v-if="
                      parametros.buzon == 'Enviados' &&
                      d.estadoOperacion == 'RECHAZADO'
                    "
                    >Rechazado
                  </span>
                </div>

                <span class="d-inline-block w-50 text-muted text-end small">
                  {{
                    d.timeStamp == null ? "Sin fecha" : formatear(d.timeStamp)
                  }}</span
                >
              </div>
              <div
                class="d-flex align-items-center justify-content-between fw-semibold"
              >
                <span class="d-inline-block w-50">Origen</span>
                <span class="d-inline-block w-50 text-end">Destino</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between small"
              >
                <span class="d-inline-block text-truncate w-50">{{
                  d.causaOrigen.dependencia.nombreOrganismo
                }}</span>
                <span class="d-inline-block text-truncate w-50 text-end">{{
                  d.causaDestino.dependencia.nombreOrganismo
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between small"
              >
                <span class="d-inline-block text-truncate w-50">{{
                  d.causaOrigen.dependencia.nombreDependencia
                }}</span>
                <span class="d-inline-block text-truncate w-50 text-end">{{
                  d.causaDestino.dependencia.nombreDependencia
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between small"
              >
                <span class="d-inline-block text-truncate w-50">{{
                  d.causaOrigen.numero
                }}</span>
                <span class="d-inline-block text-truncate w-50 text-end">{{
                  d.causaDestino.numero ? d.causaDestino.numero : " - "
                }}</span>
              </div>
              <div
                class="d-flex align-items-center justify-content-between small"
              >
                <span class="d-inline-block text-truncate w-50">{{
                  d.causaOrigen.caratula && d.causaOrigen.caratula.length >= 38
                    ? d.causaOrigen.caratula.substr(0, 38) + "..."
                    : d.causaOrigen.caratula
                }}</span>
                <span class="d-inline-block text-truncate w-50 text-end">{{
                  d.causaDestino.caratula &&
                  d.causaDestino.caratula.length >= 38
                    ? d.causaDestino.caratula.substr(0, 38) + "..."
                    : d.causaDestino.caratula
                }}</span>
              </div>
            </div>

            <div
              class="d-flex align-items-center justify-content-between small"
              v-if="d.idRespuesta != '' && d.idRespuesta != null"
            >
              <span
                class="d-inline-block text-truncate w-50 text-left"
                style="margin-left: 15px"
              >
                En respuesta a:
                <a
                  href="#"
                  class="link-primary mx-1"
                  @click="verDocumentoRespuesta(d)"
                >
                  Ver Mensaje
                </a>
              </span>
            </div>

            <hr class="my-2" v-if="d.id != null" />
          </div>
          <vue-pagination
            v-model="datos.documentos"
            :page-index="parametros.numeroPagina"
            :page-size="parametros.registrosPagina"
            @page-change="
              parametros.numeroPagina = $event;
              obtener();
            "
          >
          </vue-pagination>

          <div v-show="!parametros.loading" v-if="!datos.documentos.length">
            <div class="rounded px-3 py-2">
              <div
                class="d-flex align-items-center justify-content-center my-3"
              >
                <span class="fw-semibold fs-5"
                  >No hay documentos disponibles.</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      hide-footer
      id="VisualizarDocumentoModal"
      size="lg"
      :title="datos.titulo"
    >
      <VisualizarDocumento
        :ticket="datos.documento"
        :buzon="parametros.buzon"
        @closeModal="$bvModal.hide('VisualizarDocumentoModal')"
      />
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import vueSearcher from "@justiciacordoba/vue-searcher";
import { numeric } from "vuelidate/lib/validators";
import VisualizarDocumento from "./VisualizarDocumento.vue";
import moment from "moment";
import "moment/locale/es";
import vuePagination from "@justiciacordoba/vue-pagination";
import { formgroup } from "@justiciacordoba/vue-formgroup";
moment.locale("es");

export default {
  name: "Documentos",
  components: {
    VisualizarDocumento,
    vueSearcher,
    vuePagination,
    formgroup,
  },
  computed: {
    buzon() {
      return this.parametros.buzon;
    },
    getDependenciaDestino() {
      var url = "";
      if (this.parametros.buzon == "Recibidos") {
        url =
          "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
          this.$store.getters.user_info.codigo_organismo +
          "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]";
      } else {
        url =
          "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
          this.modelo.destino.organismo +
          "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]";
      }
      return url;
    },
    getDependenciaOrigen() {
      var url =
        "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
        this.modelo.origen.organismo +
        "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]";

      return url;
    },
    setAjaxSettings() {
      return {
        headers: { Authorization: "Bearer " + this.$store.getters.token },
      };
    },
  },
  data() {
    return {
      modelo: {
        fecha: {
          descripcion: null,
          valor: null,
        },
        origen: {
          organismo: null,
          dependencia: null,
        },
        destino: {
          organismo: null,
          dependencia: null,
        },
        filtrar: {
          fecha: false,
          origen: {
            organismo: null,
            dependencia: null,
          },
          destino: {
            organismo: null,
            dependencia: null,
          },
        },
      },
      datos: {
        filtros: {
          fecha: [
            {
              descripcion: "Hoy",
              valor: "Hoy",
            },
            {
              descripcion: "Ayer",
              valor: "Ayer",
            },
            {
              descripcion: "Última semana",
              valor: "Ultimasemana",
            },
            {
              descripcion: "Último mes",
              valor: "Ultimomes",
            },
          ],
        },
        documentos: [],
        documentosEnProceso: [],
        documento: {},
        titulo: "",
        keyDependenciaOrigen: 0,
        keyDependenciaDestino: 0,
      },
      parametros: {
        buzon: "Recibidos",
        loading: true,
        controller: null,
        numeroPagina: 0,
        registrosPagina: 10,
      },
    };
  },
  validations: {
    modelo: {
      origen: {
        organismo: {},
        dependencia: {},
      },
      destino: {
        organismo: {},
        dependencia: {},
      },
    },
    fechaDesde: {},
    fechaHasta: {},
    organismo: {},
    dependencia: {},
    numeroorigen: {
      numeric: numeric,
    },
    numerodestino: {
      numeric: numeric,
    },
  },
  methods: {
    enviarNuevoDocumento() {
      this.$router.push({ path: "/EnviarDocumento/" });
    },
    cambiarBuzon() {
      if (this.parametros.buzon == "Recibidos") {
        this.parametros.buzon = "Enviados";
        this.$router.push({ path: "/Documentos/Enviados" });
      } else {
        this.parametros.buzon = "Recibidos";
        this.$router.push({ path: "/Documentos/Recibidos" });
      }
    },
    verDocumento(d) {
      this.datos.documento = d;
      this.datos.titulo = d.id;
      this.$bvModal.show("VisualizarDocumentoModal");
    },
    toggleSidebar() {
      document.getElementById("sidebar").classList.toggle("active");
    },
    agregarFiltro(tipo, propiedad) {
      this.modelo.filtrar[tipo][propiedad] = true;
    },
    quitarFiltro(tipo, propiedad) {
      this.modelo[tipo][propiedad] = null;
      this.modelo.filtrar[tipo][propiedad] = false;
    },
    agregarFiltroFecha(fecha) {
      this.modelo.fecha.descripcion = fecha.descripcion;
      this.modelo.fecha.valor = fecha.valor;
      this.modelo.filtrar.fecha = true;
      this.obtener();
    },
    quitarFiltroFecha() {
      this.modelo.fecha.descripcion = null;
      this.modelo.fecha.valor = null;
      this.modelo.filtrar.fecha = false;
      this.obtener();
    },
    formatear(fecha) {
      let f = moment(fecha).calendar();
      return f;
    },
    obtener() {
      if (this.parametros.numeroPagina < 0) {
        return;
      }

      //Se cambian urlSearchs de dependencia origen y dependencia destino dependiendo si el buzon está en Recibidos o Enviados
      if (this.$refs.searcherDependenciaOrigen != undefined) {
        if (this.$refs.searcherDependenciaOrigen._data != undefined) {
          if (this.parametros.buzon == "Recibidos") {
            this.$refs.searcherDependenciaOrigen._data.searcher.changeSettings(
              "urlSearch",
              "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
                this.modelo.origen.organismo +
                "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]"
            );
          } else {
            this.$refs.searcherDependenciaOrigen._data.searcher.changeSettings(
              "urlSearch",
              "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
                this.$store.getters.user_info.codigo_organismo +
                "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]"
            );
            if (this.$refs.searcherDependenciaDestino != undefined) {
              this.$refs.searcherDependenciaDestino._data.searcher.changeSettings(
                "urlSearch",
                "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
                  this.modelo.destino.organismo +
                  "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]"
              );
            }
          }
        }
      }

      this.parametros.loading = true;

      this.datos.documentos = [];

      // VALIDACIONES.
      if (this.modelo.fecha.descripcion != null) {
        this.datos.filtros.fecha.forEach((fec) => {
          if (fec.descripcion == this.modelo.fecha.descripcion) {
            this.modelo.fecha.desde = fec.desde;
            this.modelo.fecha.hasta = fec.hasta;
          }
        });
      } else {
        this.modelo.fecha.desde = moment();
        this.modelo.fecha.hasta = moment();
      }

      if (this.parametros.controller) this.parametros.controller.abort();

      this.parametros.controller = new AbortController();

      axios
        .get(
          `../../api/Tickets/${this.parametros.buzon}?FechaNombre=${this.modelo.fecha.valor}&OrigenCodigoOrganismo=${this.modelo.origen.organismo}&OrigenCodigoDependencia=${this.modelo.origen.dependencia}&DestinoCodigoOrganismo=${this.modelo.destino.organismo}&DestinoCodigoDependencia=${this.modelo.destino.dependencia}&NumeroPagina=${this.parametros.numeroPagina}&RegistrosPorPagina=${this.parametros.registrosPagina}`,
          {
            signal: this.parametros.controller.signal,
          }
        )
        .then((r) => {
          if (r.data.isSuccess) {
            this.datos.documentos = r.data.data.lista.filter(
              (doc) => doc.causaOrigen != null
            );
            if (this.parametros.buzon == "Recibidos") {
              this.datos.documentos.filter(
                (doc) => doc.estadoOperacion != "EN_PROCESO"
              );
            }
            this.datos.documentosEnProceso = r.data.data.lista.filter(
              (doc) => doc.estadoOperacion == "EN_PROCESO"
            );
            this.parametros.loading = false;
          }
        })
        .catch((e) => {
          console.log(e);
          if (e.code !== "ERR_CANCELED") this.parametros.loading = false;
        });
    },
    descargar(id) {
      axios.get(`./api/Documentos/${id}`);
    },
    limpiarDependenciaDestino(scope) {
      var app = this;

      if (app.modelo.destino.organismo == "") {
        app.modelo.destino.dependencia = "";
      }

      this.forceRerender(scope);

      app.obtener();
      return true;
    },
    limpiarDependenciaOrigen(scope) {
      var app = this;

      if (app.modelo.origen.organismo == "") {
        app.modelo.origen.dependencia = "";
      }

      this.forceRerender(scope);

      app.obtener();

      return true;
    },
    forceRerender(scope) {
      scope.change();

      if (this.parametros.buzon == "Recibidos") {
        this.datos.keyDependenciaOrigen += 1;
      } else {
        this.datos.keyDependenciaDestino += 1;
      }

      this.obtener();
    },
    verDocumentoRespuesta(doc) {
      axios.get(`/api/Tickets/${doc.idRespuesta}`).then((response) => {
        this.datos.documento = response.data.data.ticket;
        //this.datos.titulo = response.data.data.ticket.documento.nombre;
        this.$bvModal.show("VisualizarDocumentoModal");
      });
    },
    moment,
  },
  watch: {
    buzon() {
      if (this.$refs.searcherDependenciaDestino != undefined) {
        this.$refs.searcherDependenciaDestino._data.searcher
          .getPrivateMethods()
          .clearValue();
        this.$refs.searcherDependenciaDestino._data.searcher
          .getPrivateMethods()
          .clearResults();
      }
      if (this.$refs.searcherDependenciaOrigen != undefined) {
        this.$refs.searcherDependenciaOrigen._data.searcher
          .getPrivateMethods()
          .clearValue();
        this.$refs.searcherDependenciaOrigen._data.searcher
          .getPrivateMethods()
          .clearResults();
      }

      this.parametros.numeroPagina = 0;

      this.obtener();
    },
    modelo: {
      handler() {
        this.datos.documentos = [];
      },
      deep: true,
    },
    "modelo.fecha.valor": {
      handler() {
        if (this.parametros.numeroPagina > 0) this.parametros.numeroPagina = 0;
      },
      deep: true,
    },
    "modelo.origen": {
      handler() {
        if (this.parametros.numeroPagina > 0) this.parametros.numeroPagina = 0;
      },
      deep: true,
    },
    "modelo.destino": {
      handler() {
        if (this.parametros.numeroPagina > 0) this.parametros.numeroPagina = 0;
      },
    },
    deep: true,
  },
  mounted() {
    if (this.$route.params.buzon) {
      this.parametros.buzon = this.$route.params.buzon;
    }
    this.obtener();
  },
};
</script>

<style>
#sidebar {
  min-width: 300px !important;
  transition: min-width ease 0.25s;
}
#sidebar.active {
  min-width: 56px !important;
}

#sidebar .btn-sidebar-toggle i {
  position: relative;
  top: 0.05em;
  color: rgba(0, 0, 0, 0.85) !important;
  transition: transform 0.25s ease !important;
  transform-origin: 0.5em 50% !important;
}
#sidebar.active .btn-sidebar-toggle i {
  color: rgba(0, 0, 0, 0.65) !important;
  transform: scaleX(-1) !important;
}
#sidebar.active .btn-sidebar-toggle span,
#sidebar.active .filter-list {
  display: none;
}

.selector-buzon.active {
  background: rgba(0, 0, 0, 0.5) !important;
}

.btn-toggle {
  color: rgba(0, 0, 0, 0.65) !important;
}
.btn-toggle i {
  padding-top: 0.1em;
  transition: transform 0.25s ease !important;
  transform-origin: 0.5em 50% !important;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, 0.85) !important;
}
.btn-toggle[aria-expanded="true"] i {
  transform: rotate(90deg) !important;
}

.filter-list,
.btn-toggle-nav {
  animation: growdown 0.25s ease forwards;
  transform-origin: top center;
}

@keyframes growdown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

.btn-quitar-filtro::before {
  position: relative;
  top: 0.05em;
}

.btn-toggle-nav .label-filtro {
  min-height: calc(1.5em + 0.5rem + 2px);
}

#buscadorDependenciaOrigen,
#buscadorOrganismo,
#buscadorDependenciaDestino {
  max-width: 12rem;
}

.JSearcher_ResultContainer {
  position: relative !important;
}
</style>
