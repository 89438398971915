<template>
  <div class="">
    <div>
      <h3>Enviar documento</h3>
    </div>

    <hr />

    <div class="d-flex flex-column flex-lg-row gap-3 mb-3">
      <div class="card flex-fill">
        <div class="card-header fw-semibold">Origen</div>

        <div class="card-body">
          <div class="row">
            <div class="form-group" v-if="modelo.dependenciaOrigenUsuario">
              <formgroup
                label="Dependencia"
                :validator="$v.modelo.dependenciaOrigenUsuario"
              >
                <input
                  class="form-control"
                  type="text"
                  disabled
                  v-model="modelo.dependenciaOrigenUsuario"
                />
              </formgroup>
            </div>

            <div
              class="form-group"
              v-else-if="
                datos.enRespuestaA && modelo.dependenciaOrigenUsuario == null
              "
            >
              <formgroup label="Dependencia">
                <input
                  class="form-control"
                  type="text"
                  disabled
                  v-model="modelo.dependenciaOrigen"
                />
              </formgroup>
            </div>
            <div
              class="form-group"
              v-else-if="
                !datos.enRespuestaA && !modelo.dependenciaOrigenUsuario
              "
            >
              <formgroup
                :validator="$v.modelo.dependenciaOrigen"
                label="Dependencia"
                v-slot="scope"
              >
                <vue-searcher
                  id="buscadorDependenciaEnvio"
                  data-field-id="codigoDependencia"
                  data-field-text="nombre"
                  page-size="10"
                  :url-search="getDependenciaOrigen"
                  :url-get="getDependenciaOrigen"
                  url-search-key="[Criterio]"
                  list-property-name="lista"
                  object-property-name="dependencia"
                  placeholder="Buscar dependencia.."
                  :allow-pagging="true"
                  v-model="modelo.dependenciaOrigen"
                  @on-change="scope.change"
                  :key="datos.key"
                  style="width: fit-content"
                  :ajax-settings="setAjaxSettings"
                  ref="searcherDependenciaOrigen"
                ></vue-searcher>
              </formgroup>
            </div>
          </div>

          <div class="row">
            <div class="form-group inputNroCausa">
              <formgroup
                label="Número de causa"
                :validator="$v.modelo.numeroCausa"
              >
                <input
                  class="form-control"
                  type="text"
                  v-model="modelo.numeroCausa"
                />
              </formgroup>
            </div>
            <div class="form-group">
              <formgroup label="Carátula" :validator="$v.modelo.caratulaOrigen">
                <input
                  class="form-control"
                  type="text"
                  v-model="modelo.caratulaOrigen"
                />
              </formgroup>
            </div>
          </div>
        </div>
      </div>

      <div class="card flex-fill">
        <div class="card-header fw-semibold">Destino</div>

        <div class="card-body">
          <div class="row">
            <div class="form-group" v-if="this.datos.enRespuestaA">
              <formgroup label="Organismo de Destino">
                <input
                  class="form-control"
                  type="text"
                  disabled
                  v-model="modelo.organismoDestinoNombre"
                />
              </formgroup>
            </div>
            <div class="form-group" v-else>
              <formgroup
                :validator="$v.modelo.organismoDestino"
                label="Organismo de Destino"
                v-slot="scope"
              >
                <vue-searcher
                  id="buscadorOrganismoEnvio"
                  data-field-id="codigoOrganismo"
                  data-field-text="nombre"
                  page-size="10"
                  url-search="../../api/Organismos/Lista?Provincia=&Nombre=[Criterio]&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]"
                  url-get="../../api/Organismos/"
                  url-search-key="[Criterio]"
                  list-property-name="lista"
                  object-property-name="organismo"
                  placeholder="Buscar organismo.."
                  :allow-pagging="true"
                  v-model="modelo.organismoDestino"
                  @on-item-selected="forceRerender(scope)"
                  @on-cleaning="limpiarDependenciaDestino(scope)"
                  style="width: fit-content"
                  :ajax-settings="setAjaxSettings"
                ></vue-searcher>
              </formgroup>
            </div>
          </div>
          <div class="row">
            <div class="form-group" v-if="this.datos.enRespuestaA">
              <formgroup label="Dependencia de Destino">
                <input
                  class="form-control"
                  type="text"
                  disabled
                  v-model="modelo.dependenciaDestinoNombre"
                />
              </formgroup>
            </div>
            <div class="form-group" v-else>
              <formgroup
                :validator="$v.modelo.dependenciaDestino"
                label="Dependencia de Destino"
              >
                <vue-searcher
                  id="buscadorDependenciaDestinoEnvio"
                  data-field-id="codigoDependencia"
                  data-field-text="nombre"
                  page-size="10"
                  :url-search="getDependenciaDestino"
                  :url-get="getDependenciaDestino"
                  url-search-key="[Criterio]"
                  list-property-name="lista"
                  object-property-name="dependencia"
                  placeholder="Buscar dependencia.."
                  :allow-pagging="true"
                  v-model="modelo.dependenciaDestino"
                  :key="datos.keyDestino"
                  style="width: fit-content"
                  :ajax-settings="setAjaxSettings"
                ></vue-searcher>
              </formgroup>
            </div>
          </div>

          <div class="row">
            <div class="form-group inputNroCausa">
              <formgroup
                label="Número de causa destino"
                :validator="$v.modelo.numeroCausaDestino"
              >
                <input
                  class="form-control"
                  type="text"
                  :disabled="datos.enRespuestaA"
                  v-model="modelo.numeroCausaDestino"
                />
              </formgroup>
            </div>
            <div class="form-group">
              <formgroup
                label="Carátula"
                :validator="$v.modelo.caratulaOrigenDestino"
              >
                <input
                  class="form-control"
                  type="text"
                  :disabled="datos.enRespuestaA"
                  v-model="modelo.caratulaDestino"
                />
              </formgroup>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card mb-3">
      <div class="card-header fw-semibold">Documento</div>
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <div class="input-group input-group-sm">
                <input
                  type="file"
                  class="form-control"
                  id="documento"
                  ref="myfile"
                  accept=".pdf"
                  required
                />
                <label class="input-group-text" for="documento">
                  <i class="bi bi-file-earmark-arrow-up"></i>
                </label>
              </div>
            </div>
            <div class="form-group">
              <formgroup
                label="Observaciones"
                :validator="$v.modelo.observaciones"
              >
                <div class="input-group input-group-sm">
                  <textarea
                    class="form-control"
                    type="text"
                    rows="3"
                    maxlength="1000"
                    v-model="modelo.observaciones"
                  >
                  </textarea>
                </div>
              </formgroup>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3">
        <div class="card-header">Adjuntos</div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <div class="input-group input-group-sm">
                  <input
                    type="file"
                    class="form-control"
                    id="adjunto1"
                    ref="adjuntos"
                    accept="*.*"
                  />
                  <label class="input-group-text" for="adjunto1">
                    <i class="bi bi-file-earmark-arrow-up"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn btn-primary" @click="enviarDocumento">
        Enviar
      </button>
      <button
        type="button"
        class="btn btn-secondary d-none"
        @click="$router.back()"
      >
        Volver
      </button>
    </div>
  </div>
</template>

<script>
import { formgroup } from "@justiciacordoba/vue-formgroup";
import axios from "axios";
import vueSearcher from "@justiciacordoba/vue-searcher";
import { showSuccess, showError } from "@justiciacordoba/js-messages";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "EnviarDocumento",
  components: {
    vueSearcher,
    formgroup,
  },
  data() {
    return {
      datos: {
        key: 0,
        keyDestino: 0,
        enRespuestaA: false,
      },
      modelo: {
        organismo: "Organismo usuario logueado",
        dependenciaOrigen: null,
        codigoDependenciaOrigen: null,
        numeroCausa: null,
        organismoDestino: null,
        organismoDestinoNombre: null,
        dependenciaDestino: null,
        dependenciaDestinoNombre: null,
        numeroCausaDestino: "",
        caratulaOrigen: null,
        caratulaDestino: "",
        guidRespuesta: null,
        seleccionarDocumento: null,
        observaciones: "",
      },
    };
  },
  validations: {
    modelo: {
      dependenciaOrigen: {
        required: requiredIf((value) => {
          return !value.dependenciaOrigenUsuario;
        }),
      },
      dependenciaOrigenUsuario: {},
      numeroCausa: { required: required },
      organismoDestino: { required: required },
      dependenciaDestino: { required: required },
      caratulaOrigen: { required: required },
      numeroCausaDestino: {},
      caratulaOrigenDestino: {},
      observaciones: {},
    },
  },
  computed: {
    getDependenciaOrigen() {
      var url = "";

      url =
        "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
        this.$store.getters.user_info.codigo_organismo +
        "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]";

      return url;
    },
    getDependenciaDestino() {
      var url = "";

      if (this.$route.params.dependenciaDestino == undefined) {
        url =
          "../../api/Dependencias/ListaSearcher?Nombre=[Criterio]&CodigoOrganismo=" +
          this.modelo.organismoDestino +
          "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]";
      } else {
        url =
          "../../api/Dependencias/GetByCodigoDependenciaSearcher?CodigoOrganismo=" +
          this.$route.params.organismoDestino +
          "&CodigoDependencia=" +
          this.$route.params.dependenciaDestino +
          "&NumeroPagina=[PageIndex]&RegistrosPorPagina=[PageSize]";
      }

      return url;
    },
    setAjaxSettings() {
      return {
        headers: { Authorization: "Bearer " + this.$store.getters.token },
      };
    },
  },
  methods: {
    limpiarDependenciaDestino(scope) {
      var app = this;

      app.modelo.dependenciaDestino = "";
      this.forceRerender(scope);

      return true;
    },
    forceRerender(scope) {
      if (!this.datos.enRespuestaA) {
        scope.change();
        this.datos.keyDestino += 1;
      }
      // this.datos.enRespuestaA = false;
    },
    enviarDocumento() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      var formData = new FormData();

      var docSeleccionado = this.$refs.myfile.files.item(0);

      if (docSeleccionado.type != "application/pdf") {
        showError("El archivo debe ser un .PDF");
        return;
      }
      formData.append("file", docSeleccionado);

      var docAdjuntos = this.$refs.adjuntos.files;
      for (var a = 0; a < docAdjuntos.length; a++) {
        formData.append("adjuntos", docAdjuntos[a]);
        //formData.append("adj-" + a , docAdjuntos[a]);
      }

      var idRespuesta =
        this.$route.params.guid == undefined ? "" : this.$route.params.guid;

      var codDependencia = "";
      if (
        this.modelo.dependenciaOrigenUsuario != null ||
        this.modelo.dependenciaOrigenUsuario != undefined
      ) {
        codDependencia = this.modelo.codigoDependenciaOrigen;
      } else if (this.modelo.codigoDependenciaOrigen != undefined) {
        codDependencia = this.modelo.codigoDependenciaOrigen;
      } else {
        codDependencia = this.modelo.dependenciaOrigen;
      }

      const objetoJSON = {
        IdRespuesta: idRespuesta,
        Observaciones: this.modelo.observaciones,
        CausaOrigen: {
          Numero: this.modelo.numeroCausa,
          Caratula: this.modelo.caratulaOrigen,
          CodigoDependencia: codDependencia,
          CodigoOrganismo: this.$store.getters.user_info.codigo_organismo,
        },
        CausaDestino: {
          Numero: this.modelo.numeroCausaDestino,
          Caratula: this.modelo.caratulaDestino,
          CodigoDependencia: this.modelo.dependenciaDestino,
          CodigoOrganismo: this.modelo.organismoDestino,
        },
      };

      formData.append("jsonReq", JSON.stringify(objetoJSON));

      axios
        .post("../../api/Documentos/Enviar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("response.isSuccess");
          if (!response.data.isSuccess) {
            showError(response.data.errors.errorMessage);
          } else {
            showSuccess("El archivo se envió correctamente.");
            console.log(response);

            this.$router.push({ path: "/Documentos/Enviados" });
          }
        })
        .catch(() => {
          showError("El archivo no se pudo enviar correctamente.");
          console.log("Could not upload the file!");
        });
    },
  },
  created() {
    if (this.$store.getters.nombreDependencia !== "") {
      this.modelo.dependenciaOrigenUsuario =
        this.$store.getters.nombreDependencia;
      this.modelo.codigoDependenciaOrigen =
        this.$store.getters.user_info.codigo_dependencia;
    }

    if (this.$route.params != undefined) {
      var respuesta = this.$route.params;

      console.log("Respuesta:");
      console.log(respuesta);

      this.modelo.guidRespuesta = respuesta.guid;

      if (this.modelo.guidRespuesta != undefined) {
        this.modelo.organismo = respuesta.organismoOrigen;
        // if (this.modelo.dependenciaOrigen == null)
        this.modelo.dependenciaOrigen = respuesta.dependenciaOrigen;
        if (this.modelo.codigoDependenciaOrigen == undefined)
          this.modelo.codigoDependenciaOrigen =
            respuesta.dependenciaOrigenCodigo;
        this.modelo.numeroCausa = respuesta.numeroOrigen;
        this.modelo.caratulaOrigen = respuesta.caratulaOrigen;
        this.modelo.organismoDestino = respuesta.organismoDestino;
        this.modelo.numeroCausaDestino = respuesta.numeroDestino;
        this.modelo.caratulaDestino = respuesta.caratulaDestino;
        this.modelo.dependenciaDestino = respuesta.dependenciaDestino;
        this.modelo.organismoDestinoNombre = respuesta.organismoDestinoNombre;
        this.modelo.dependenciaDestinoNombre =
          respuesta.dependenciaDestinoNombre;
        this.modelo.observaciones = respuesta.observaciones;
      }
      this.datos.enRespuestaA = respuesta.guid ? true : false;
    } else if (
      this.$route.params.length == undefined &&
      this.$store.getters.codigoDependencia != ""
    ) {
      this.modelo.dependenciaOrigen = this.$store.getters.codigoDependencia;

      // this.$refs.searcherDependenciaOrigen.searcher.getPrivateMethods().setText(this.$store.getters.nombreDependencia);
      // this.$refs.searcherDependenciaOrigen.searcher.getPrivateMethods().setValue(this.$store.getters.codigoDependencia);
    }
  },
};
</script>

<style>
.inputNroCausa {
  width: auto !important;
}

#buscadorDependenciaEnvio,
#buscadorOrganismoEnvio,
#buscadorDependenciaDestinoEnvio {
  width: 400px !important;
}
</style>
