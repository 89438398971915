const state = {
    logged_in: false,
    user_info: '',
    token: '',
    logout: '',
    esDependenciaAdmin: false,
    codigoDependencia: '',
    nombreDependencia: '',
  }
  
  const getters = {
    logged_in: (state) => {
      return state.logged_in
    },
    user_info: (state) => {
      return state.user_info
    },
    token: (state) => {
      return state.token
    },
    logout: (state) => {
      return state.logout
    },
    esDependenciaAdmin: (state) => {
      return state.esDependenciaAdmin
    },
    codigoDependencia: (state) => {
      return state.codigoDependencia
    },
    nombreDependencia: (state) => {
      return state.nombreDependencia
    }
  }
  
  const actions = {
    logOut ({ commit }) {
      commit('setLoggedIn', false)
    }
  }
  
  const mutations = {
    setLoggedIn (state, logged) {
      state.logged_in = logged
    },
    setUserInfo (state, info) {
      state.user_info = info
    },
    setToken (state, token) {
      state.token = token
    },
    setLogOut (state, logout) {
      state.logout = logout
    },
    setEsDependenciaAdmin (state, esDependenciaAdmin) {
      state.esDependenciaAdmin = esDependenciaAdmin
    },
    setCodigoDependencia (state, codigoDependencia) {
      state.codigoDependencia = codigoDependencia
    },
    setNombreDependencia (state, nombreDependencia) {
      state.nombreDependencia = nombreDependencia
    }
  }

  
  export default {
    state,
    getters,
    actions,
    mutations
  }
  