
export default function (Guardar, Obtener, Borrar, Nuevo) {
    return {

        created: function () {
            this.LeerParametros_ABMMixin()
        },
        props: ['id'],
        data() {
            return {
                parametros: {
                    id: null
                },
                ABMEstado:
                {
                    nuevo: false,
                    edicion: false,
                    soloLectura: false,
                    modificado: false,
                    id: 0
                }
            }
        },
        methods: {

            ABMModoNuevo: function () {
                this.ABMEstado.nuevo = true;
                this.ABMEstado.edicion = false;
                this.ABMEstado.soloLectura = false;
                this.ABMEstado.modificado = false;
                this.$v.$reset();
            },
            ABMModoEdicion: function (id) {
                this.ABMEstado.nuevo = false;
                this.ABMEstado.edicion = true;
                this.ABMEstado.soloLectura = false;
                this.ABMEstado.modificado = false;
                if (id != null) {
                    this.ABMEstado.id = id;
                }
                this.$v.$reset();
            },
            ABMModoSoloLectura: function () {
                this.ABMEstado.nuevo = false;
                this.ABMEstado.edicion = false;
                this.ABMEstado.soloLectura = true;
            },
            ABMModificado: function (value) {
                this.ABMEstado.modificado = value;
            },
            obtener: function (id) {
                if (id) {
                    Obtener(this, id)
                }
                else {
                    Obtener(this, this.ABMEstado.id);
                }
            },
            guardar: function () {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                Guardar(this)
            },
            borrar: function () {
                Borrar(this);
            },
            nuevo: function () {
                Nuevo(this);
            },
            LeerParametros_ABMMixin: function () {
                var app = this;
                app.parametros.id = app.id;
                if (app.parametros.id) {
                    app.obtener(app.parametros.id);
                }
                else {
                    app.nuevo();
                }
            }

        },
        watch: {
            '$v.$anyDirty': function _watch$vSomeField$invalid(value) {
                this.ABMModificado(value);
            }
        }
    }
}

