<template>
  <div class="d-flex flex-column gap-3">
    <!-- Identificadores -->
    <div class="card">
      <div class="card-header fw-semibold">
        Identificadores
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Código de dependencia"
                v-model="modelo.dependencia.idDependencia.codigoDependencia"
                :validator="
                  $v.modelo.dependencia.idDependencia.codigoDependencia
                "
                :read-only="isReadOnly()"
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Identificador interno"
                v-model="modelo.dependencia.idInterno"
                :validator="$v.modelo.dependencia.idInterno"
              ></formgroup-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Datos generales -->
    <div class="card">
      <div class="card-header fw-semibold">
        Datos generales
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Nombre de la dependencia"
                v-model="modelo.dependencia.nombre"
                :validator="$v.modelo.dependencia.nombre"
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Fuero"
                v-model="modelo.dependencia.fuero"
                :validator="$v.modelo.dependencia.fuero"
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Instancia"
                v-model="modelo.dependencia.instancia"
                :validator="$v.modelo.dependencia.instancia"
              ></formgroup-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <formgroup-textarea
                rows="2"
                label="Descripción"
                v-model="modelo.dependencia.descripcion"
                :validator="$v.modelo.dependencia.descripcion"
              ></formgroup-textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Ubicación -->
    <div class="card">
      <div class="card-header fw-semibold">
        Ubicación
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Ciudad"
                v-model="modelo.dependencia.ubicacion.ciudad"
                :validator="$v.modelo.dependencia.ubicacion.ciudad"
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Calle"
                v-model="modelo.dependencia.ubicacion.calle"
                :validator="$v.modelo.dependencia.ubicacion.calle"
              ></formgroup-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Número"
                v-model="modelo.dependencia.ubicacion.numero"
                :validator="$v.modelo.dependencia.ubicacion.numero"
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Piso y departamento"
                v-model="modelo.dependencia.ubicacion.pisoDpto"
                :validator="$v.modelo.dependencia.ubicacion.pisoDpto"
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Código postal"
                v-model="modelo.dependencia.ubicacion.cp"
                :validator="$v.modelo.dependencia.ubicacion.cp"
              ></formgroup-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Nombre de agrupacion geográfica"
                v-model="modelo.dependencia.ubicacion.nombreAgrupacionGeo"
                :validator="$v.modelo.dependencia.ubicacion.nombreAgrupacionGeo"
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Tipo de agrupacion geográfica"
                v-model="modelo.dependencia.ubicacion.tipoAgrupacionGeo"
                :validator="$v.modelo.dependencia.ubicacion.tipoAgrupacionGeo"
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Latitud"
                v-model="modelo.dependencia.ubicacion.georefCentroideLat"
                :validator="$v.modelo.dependencia.ubicacion.georefCentroideLat"
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Longitud"
                v-model="modelo.dependencia.ubicacion.georefCentroideLon"
                :validator="$v.modelo.dependencia.ubicacion.georefCentroideLon"
              ></formgroup-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Datos de contacto -->
    <div class="card">
      <div class="card-header fw-semibold">
        Datos de contacto
      </div>

      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Nombre completo"
                v-model="modelo.dependencia.datosContacto.nombreCompleto"
                :validator="$v.modelo.dependencia.datosContacto.nombreCompleto"
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Correo electrónico"
                v-model="modelo.dependencia.datosContacto.correoElectronico"
                :validator="
                  $v.modelo.dependencia.datosContacto.correoElectronico
                "
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Teléfono"
                v-model="modelo.dependencia.datosContacto.telefono"
                :validator="$v.modelo.dependencia.datosContacto.telefono"
              ></formgroup-input>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Teléfono de urgencia"
                v-model="modelo.dependencia.datosContacto.telefonoUrgencia"
                :validator="
                  $v.modelo.dependencia.datosContacto.telefonoUrgencia
                "
              ></formgroup-input>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <formgroup-input
                label="Teléfono de guardia"
                v-model="modelo.dependencia.datosContacto.telefonoGuardia"
                :validator="$v.modelo.dependencia.datosContacto.telefonoGuardia"
              ></formgroup-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Botones -->
    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn btn-primary" @click="guardar">
        Guardar
      </button>
      <button type="button" class="btn btn-danger" @click="borrar">
        Eliminar
      </button>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import {
  formgroupInput,
  formgroupTextarea,
} from "@justiciacordoba/vue-formgroup";
import { showSuccess, showError } from "@justiciacordoba/js-messages";
import { required, email, decimal } from "vuelidate/lib/validators";
import modal from "@justiciacordoba/js-modal";
import mixin from "../../../components/vue-ABMMixins";
let mixins = mixin(Guardar, Obtener, Borrar, Nuevo);
const ControllerRoute = "./api/Dependencias";

export default {
  name: "DependenciasABM",
  mixins: [mixins],
  components: {
    formgroupInput,
    formgroupTextarea,
  },
  data() {
    return {
      modelo: {
        dependencia: {
          idInterno: null,
          idDependencia: { codigoDependencia: uuidv4(), codigoOrganismo: this.$store.getters.codigo_organismo },
          nombre: null,
          descripcion: null,
          fuero: null,
          instancia: null,
          datosContacto: {
            correoElectronico: null,
            nombreCompleto: null,
            telefono: null,
            telefonoUrgencia: null,
            telefonoGuardia: null,
          },
          ubicacion: {
            ciudad: null,
            calle: null,
            numero: null,
            pisoDpto: null,
            cp: null,
            nombreAgrupacionGeo: null,
            tipoAgrupacionGeo: null,
            georefCentroideLat: null,
            georefCentroideLon: null,
          },
        },
      },
    };
  },
  validations: {
    modelo: {
      dependencia: {
        idInterno: {},
        idDependencia: { codigoDependencia: { required: required } },
        nombre: {required: required},
        descripcion: {},
        fuero: {required: required},
        instancia: {required: required},
        datosContacto: {
          correoElectronico: { email: email, required: required },
          nombreCompleto: {required: required},
          telefono: {required: required},
          telefonoUrgencia: {},
          telefonoGuardia: {},
        },
        ubicacion: {
          ciudad: {},
          calle: {},
          numero: { },
          pisoDpto: {},
          cp: { },
          nombreAgrupacionGeo: {},
          tipoAgrupacionGeo: {},
          georefCentroideLat: { decimal: decimal },
          georefCentroideLon: { decimal: decimal },
        },
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    isReadOnly(){
      return this.id != '';
    },
  },
};

function Guardar(app) {
  if (app.ABMEstado.edicion) {
    axios
      .put(ControllerRoute + "/Modificar", app.modelo)
      .then(function(response) {
        if (response.data.isSuccess) {
          showSuccess("El registró se actualizó correctamente.");
          app.closeModal();
        } else {
          showError("Error al modificar dependencia. ERROR: " + response.data.errors[response.data.errors.length - 1].errorMessage);
        }
      });
  } else {
    axios.post(ControllerRoute + "/Crear", app.modelo).then(function(response) {
      if (response.data.isSuccess) {
        if (response.data.errors.length > 0) {
          showError("Error al crear dependencia. ERROR: " + response.data.errors[response.data.errors.length - 1].errorMessage);
        } else {
          showSuccess("La dependencia se creó correctamente.");
          app.closeModal();
        }
      }
    });
  }
}

function Borrar(app) {
  if (app.ABMEstado.edicion) {
    var options = {
      confirmTitle: "Borrar",
      confirmMessage: "¿Confirma que desea borrar el registro?",
      confirmOk: "Borrar",
      confirmCancel: "Cancelar",
      confirmDirection: "ltr",
      confirmStyle: "danger",
      confirmCallback: function() {
        axios
          .delete(ControllerRoute + "/Borrar", {
            headers: { "Content-Type": "application/json" },
            data: {
              codigo_dependencia: app.modelo.dependencia.idDependencia.codigoDependencia,
            },
          })
          .then(function(response) {
            if (!response.data.hayError) {
              console.log(app.modelo);
              showSuccess("El registro se eliminó correctamente.");
              app.closeModal();
            }
          });
      },
    };
    modal.confirmModal(options);
  }
}

function Obtener(app, id) {
  if (id) {
    axios.get(ControllerRoute + "/" + id).then(function(response) {
      if (!response.data.data.hayError) {
        console.log(response);
        var temp = response.data.data;
        app.modelo = temp;
      }
    });
  }
  app.ABMModoEdicion(id);
}

function Nuevo(app) {
  app.ABMModoNuevo();
}
</script>

<style></style>
