<template>
  <div>
    <div v-if="isLoggedin">Cargando ...</div>
    <div v-else class="container d-flex flex-column gap-3">
      <div
        aria-live="polite"
        aria-atomic="true"
        style="position: relative; z-index: 20000"
      >
        <div
          id="toastContent"
          style="position: absolute; top: 10px; right: 10px"
        ></div>
      </div>

      <img
        src="./assets/svg/layered-waves-haikei-1.svg"
        style="
          position: fixed;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          z-index: -9999;
        "
      />

      <div>
        <nav class="navbar navbar-light navbar-expand-lg bg-white rounded">
          <div class="container-fluid px-3">
            <router-link
              class="navbar-brand border-right p-0"
              to="/Documentos/Recibidos"
            >
              <img src="./assets/img/logo.jpg" alt="" height="50" />
            </router-link>

            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="bi bi-list"></i>
            </button>

            <div class="collapse navbar-collapse" id="navbarText">
              <ul class="navbar-nav ms-2 me-auto mb-2 mb-lg-0">
                <li
                  class="nav-item"
                  v-if="this.$store.getters.esDependenciaAdmin"
                >
                  <router-link class="nav-link" to="/Dependencias">
                    Dependencias
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    aria-current="page"
                    to="/Documentos/Recibidos"
                  >
                    Documentos
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link class="nav-link" to="/EnviarDocumento/">
                    Enviar documento
                  </router-link>
                </li>

                <li class="nav-item">
                  <router-link class="nav-link" to="/EstadoBus">
                    Estado del bus
                  </router-link>
                </li>
              </ul>
            </div>
            <div class="d-flex flex-column align-items-end">
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="btnUsuario"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-person-circle me-1"></i>
                  {{ this.$store.state.user.user_info.preferred_username }}
                </button>
                <ul
                  class="dropdown-menu dropdown-menu-end"
                  aria-labelledby="btnUsuario"
                >
                  <li>
                    <a class="dropdown-item" href="#" @click="verUsuario()"
                      >Mi perfil</a
                    >
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item" :href="$store.state.user.logout"
                      >Cerrar sesión</a
                    >
                  </li>
                </ul>
              </div>
              <div class="small">
                <span class="small organismoUsuario">
                  {{ this.$store.getters.user_info.codigo_organismo }}
                  <span v-if="this.$store.getters.nombreDependencia"> - </span>
                </span>
                <span class="small organismoUsuario">
                  {{ this.$store.getters.nombreDependencia }}
                </span>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div
        class="d-flex flex-column bg-white rounded p-3 flex-grow-1"
        id="viewport"
      >
        <router-view />
      </div>

      <div class="mb-3" style="z-index: 1000">
        <nav class="navbar navbar-light navbar-expand-lg bg-white rounded">
          <div
            class="container-fluid px-3 d-flex flex-wrap justify-content-between align-items-center"
          >
            <p
              class="mb-0"
              title="Junta Federal de Cortes y Superiores Tribunales de Justicia de las Provincias Argentinas y Ciudad Autónoma de Buenos Aires"
            >
              <a
                href="http://www.jufejus.org.ar"
                target="_blank"
                class="text-muted linksFooter"
                >&copy; 2023, Ju.Fe.Jus.</a
              >
            </p>

            <div class="navbar-brand m-0 p-0">
              <img src="./assets/img/logo2.jpg" alt="" height="50" />
            </div>

            <p
              class="mb-0"
              title="Junta Federal de Cortes y Superiores Tribunales de Justicia de las Provincias Argentinas y Ciudad Autónoma de Buenos Aires"
            >
              <a
                href="http://www.bus-justicia.org.ar"
                target="_blank"
                class="text-muted linksFooter"
                >BUS - Justicia</a
              >
            </p>
          </div>
        </nav>
      </div>
      <b-modal
        hide-footer
        id="UsuarioLogueadoModal"
        size="md"
        :title="datos.tituloModal"
      >
        <UsuarioLogueado @closeModal="$bvModal.hide('UsuarioLogueadoModal')" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import UsuarioLogueado from "./pages/UsuarioLogueado.vue";

export default {
  name: "App",
  components: { UsuarioLogueado },
  data() {
    return {
      modelo: {},
      datos: {
        tituloModal: "",
        version: process.env.VUE_APP_VERSION,
      },
    };
  },
  methods: {
    verUsuario() {
      let app = this;
      app.datos.tituloModal = "Mi perfil";
      app.$bvModal.show("UsuarioLogueadoModal");
    },
    cambiarPagina(e) {
      let navs = document.getElementsByClassName("nav-item");

      for (let element of navs) {
        element.classList.remove("active");
      }

      e.target.classList.add("active");
    },
  },
  computed: {
    isLoggedin() {
      return !this.$store.getters.logged_in;
    },
  },
};
</script>

<style>
#viewport > div {
  flex: 1 1 !important;
}

.container {
  height: auto !important; /* real browsers */
  min-height: 100%; /* real browsers */
}

.linksFooter {
  text-decoration: none;
}

.linksFooter:hover {
  text-decoration: underline;
}

.modal-backdrop {
  opacity: 60% !important;
}

.nav-link.router-link-exact-active {
  color: black !important;
  border-bottom: 2px solid #6c757d !important;
}

.organismoUsuario {
  color: #4e8ebc;
  font-weight: 500;
}
</style>
