import Vue from "vue";
import VueRouter from "vue-router";
import Inicio from "../pages/Inicio.vue";
import Dependencias from "../pages/Dependencias/Dependencias.vue";
import Documentos from "../pages/Documentos/Documentos.vue";
import EnviarDocumento from "../pages/Documentos/EnviarDocumento.vue";
import VisualizarDocumento from "../pages/Documentos/VisualizarDocumento.vue";
import EstadoBus from "../pages/EstadoBus.vue";
import UsuarioLogueado from "../pages/UsuarioLogueado.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },
  {
    path: '/Dependencias',
    name: 'Dependencias',
    component: Dependencias
  },
  {
    path: '/Documentos/:buzon',
    name: 'Documentos',
    component: Documentos
  },
  {
    path: '/EnviarDocumento',
    name: 'EnviarDocumento',
    component: EnviarDocumento
  },
  {
    path: '/VisualizarDocumento',
    name: 'VisualizarDocumento',
    component: VisualizarDocumento
  },
  {
    path: '/EstadoBus',
    name: 'EstadoBus',
    component: EstadoBus
  },
  {
    path: '/UsuarioLogueado',
    name: 'UsuarioLogueado',
    component: UsuarioLogueado
  },
];

const router = new VueRouter({
  mode: 'history',
  routes: routes
});

export default router;
