<template>
  <div>
    <div>
      <h3>Estado del bus</h3>

      <hr />

      <div class="text-center py-3" v-show="parametros.loading">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Cargando...</span>
        </div>
      </div>

      <div v-show="!parametros.loading">
        <div class="mt-3">
          <div class="rounded border overflow-hidden">
            <table class="table table-borderless align-middle m-0">
              <thead class="table-light">
                <tr>
                  <th scope="col" class="w-75">Servicio</th>
                  <th scope="col" class="text-center">Estado</th>
                </tr>
              </thead>

              <tbody class="border-top-0">
                <tr class="border-top" v-if="datos.estados.bd">
                  <td scope="row" data-label="Mensaje">
                    {{ datos.estados.bd.message }}
                  </td>
                  <td scope="row" data-label="Servicio" class="text-center">
                    <i
                      class="bi bi-check-circle-fill text-success"
                      v-if="datos.estados.bd.status"
                    ></i>
                    <i
                      class="bi bi-exclamation-circle-fill text-warning"
                      v-if="false"
                    ></i>
                    <i
                      class="bi bi-x-circle-fill text-danger"
                      v-if="!datos.estados.bd.status"
                    ></i>
                  </td>
                </tr>
                <tr class="border-top">
                  <td scope="row" data-label="Mensaje">
                    Sitio Api
                  </td>
                  <td scope="row" data-label="Mensaje" style="text-align:center">
                    {{ datos.estados.apiVersion }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-show="!parametros.loading">
        <div class="mt-3">
          <div class="rounded border overflow-hidden">
            <table class="table table-borderless align-middle m-0">
              <thead class="table-light">
                <tr>
                  <th scope="col" class="w-75">Kafka</th>
                  <th scope="col" class="text-center">Estado</th>
                </tr>
              </thead>

              <tbody class="border-top-0">
                <tr
                  class="border-top"
                  v-for="(e, i) in datos.estados.kafka"
                  :key="i"
                >
                  <td scope="row" data-label="Mensaje" class="w-75">
                    {{ e.message }}
                  </td>
                  <td scope="row" data-label="Estado" class="text-center">
                    <i
                      class="bi bi-check-circle-fill text-success"
                      v-if="e.status"
                    ></i>
                    <i
                      class="bi bi-exclamation-circle-fill text-warning"
                      v-if="false"
                    ></i>
                    <i
                      class="bi bi-x-circle-fill text-danger"
                      v-if="!e.status"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          class="d-flex align-items-center justify-content-between  rounded px-3 py-2 small"
        >
          <ol class="breadcrumb m-0 py-2"></ol>

          <div
            class="btn-toolbar gap-2"
            role="toolbar"
            aria-label="Barra de herramientas"
          >
            <button
              type="button"
              class="btn btn-sm btn-primary"
              title="Recargar"
              @click="obtener"
            >
              <i class="bi bi-arrow-clockwise"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EstadoBus",
  data() {
    return {
      datos: {
        estados: {
          bd: null,
          kafka: [],
          apiVersion: null,
        },
      },
      parametros: {
        loading: true,
      },
    };
  },
  validations: {
    modelo: {
      descripcion: {},
    },
  },
  mounted() {
    this.obtener();
  },
  methods: {
    obtener() {
      this.parametros.loading = true;

      axios.get("./api/Status").then((r) => {
        if (!r.data.data.hayError) {
          this.datos.estados.bd = r.data.data.Status.data.database;
          this.datos.estados.kafka = r.data.data.Status.data.kafka;
          this.datos.estados.apiVersion = r.data.data.apiVersion;
          this.parametros.loading = false;
        }
      });
    },
  },
};
</script>

<style></style>
