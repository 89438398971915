<template>
  <div class="d-flex flex-column gap-3">
    <div class="card">
      <div class="card-header fw-semibold">Datos generales</div>

      <div class="card-body">
        <div class="d-flex flex-column rounded px-3 gap-1">
          <div class="d-flex align-items-center justify-content-between fw-semibold">
            <span class="d-inline-block w-50 text-muted">
              {{ ticket.id }}</span>
            <span class="d-inline-block text-muted w-50 text-end">{{
              formatear(ticket.timeStamp)
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between fw-semibold">
            <span class="d-inline-block w-50">Origen</span>
            <span class="d-inline-block w-50 text-end">Destino</span>
          </div>
          <div class="d-flex align-items-center justify-content-between small">
            <span class="d-inline-block text-truncate w-50">{{
              ticket.causaOrigen.dependencia.nombreOrganismo
            }}</span>
            <span class="d-inline-block text-truncate w-50 text-end">{{
              ticket.causaDestino.dependencia.nombreOrganismo
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between small">
            <span class="d-inline-block text-truncate w-50">{{
              ticket.causaOrigen.dependencia.nombreDependencia
            }}</span>
            <span class="d-inline-block text-truncate w-50 text-end">{{
              ticket.causaDestino.dependencia.nombreDependencia
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between small">
            <span class="d-inline-block text-truncate w-50">{{
              ticket.causaOrigen.numero
            }}</span>
            <span class="d-inline-block text-truncate w-50 text-end">{{
              ticket.causaDestino.numero
            }}</span>
          </div>
          <div class="d-flex align-items-center justify-content-between small spacing">
            <span class="d-inline-block text-truncate w-50">{{
              ticket.causaOrigen.caratula && ticket.causaOrigen.caratula.length >= 38
              ? ticket.causaOrigen.caratula.substr(0, 38) + "..."
              : ticket.causaOrigen.caratula
            }}</span>
            <span class="d-inline-block text-truncate w-50 text-end">{{
              ticket.causaDestino.caratula && ticket.causaDestino.caratula.length >= 38
              ? ticket.causaDestino.caratula.substr(0, 38) + "..."
              : ticket.causaDestino.caratula
            }}</span>
          </div>

          <div class="d-flex align-items-center justify-content-between " 
            v-if="this.ticket.observaciones != '' ">
            <span class="d-inline-block ">Observaciones</span>  
          </div>
          <div class="d-flex align-items-center justify-content-between small">
            <span class="d-inline-block ">{{
              ticket.observaciones
            }}</span>
            
          </div>

          <div class="d-flex align-items-center justify-content-between small spacing"
            v-if="this.ticket.recibido == null && buzon === 'Recibidos'">
            <span class="badge bg-primary"> Nuevo </span>
          </div>
          
          <div class="d-flex align-items-center justify-content-between small spacing"
            v-if="this.ticket.recibido != null">
            <span class="badge bg-success">
              Leído el
              {{ fechaLeida }}
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-between small">
            <span class="badge bg-danger" v-if="ticket.estadoOperacion == 'RECHAZADO'">
              Rechazado
            </span>
          </div>
          <div class="d-flex align-items-center justify-content-between small spacing">
            <span v-if="ticket.estadoOperacion == 'RECHAZADO'" class="msgRechazado">{{ ticket.motivoEstado }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion" id="accordionExample2">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBFA"
            aria-expanded="false" aria-controls="collapseBFA">
            <span class="fw-semibold">Registros BFA</span>
          </button>
        </h2>

        <div id="collapseBFA" class="accordion-collapse collapse" aria-labelledby="headingOne">
          <div class="accordion-body p-3">
            <div class="d-flex align-items-center text-truncate justify-content-between">
              <span class="d-inline-block fw-semibold">Whostamped</span>
              <span class="d-inline-block">{{
                ticket.regBFA.whostamped
              }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <span class="d-inline-block fw-semibold">Blocknumber</span>
              <span class="d-inline-block">{{
                ticket.regBFA.blocknumber
              }}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <span class="d-inline-block fw-semibold">Blocktimestamp</span>
              <span class="d-inline-block">{{
                ticket.regBFA.blocktimestamp
              }}</span>
            </div>
            <div class="d-flex align-items-center text-truncate justify-content-between">
              <a :href="`https://bfa.ar/sello2#/hash/${ticket.hash}`" target="_blank">{{
                `https://bfa.ar/sello2#/hash/${ticket.hash}` }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion" id="accordionExample1">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
            aria-expanded="false" aria-controls="collapseOne">
            <span class="fw-semibold">Transacciones</span>
          </button>
        </h2>

        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne">
          <div class="accordion-body p-3">
            <div class="rounded border overflow-hidden">
              <table class="table table-borderless align-middle m-0">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Código Mensaje</th>
                    <th scope="col">Tipo Mensaje</th>
                    <th scope="col">Fecha</th>
                  </tr>
                </thead>

                <tbody class="border-top-0">
                  <tr class="border-top" v-for="(d, i) in ticket.transacciones" :key="i">
                    <td scope="row" data-label="#">
                      <span v-if="d.codigoMensaje == 'DOCUMENTO_ENVIADO'">
                        Registro de documento nuevo
                      </span>
                      <span v-if="d.codigoMensaje == 'ENTREGA_BROKER'">
                        Validación de políticas de seguridad
                      </span>
                      <span v-if="d.codigoMensaje == 'ENTREGA_DESTINO'">
                        Documento disponible para entrega
                      </span>
                      <span v-if="d.codigoMensaje == 'RECHAZA_BROKER'">
                        Documento rechazado
                      </span>
                    </td>
                    <td scope="row" data-label="Asunto">
                      {{ d.tipoMensaje }}
                    </td>
                    <td scope="row" data-label="Asunto">
                      {{ timeStamp(d.timeStamp) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion" id="accordionExample3" v-if="this.documento != null && this.documento.data.adjuntos != null">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAdj"
            aria-expanded="false" aria-controls="collapseAdj">
            <span class="fw-semibold">Adjuntos</span>
          </button>
        </h2>

        <div id="collapseAdj" class="accordion-collapse collapse" aria-labelledby="headingOne">
          <div class="accordion-body p-3">
            <div class="rounded border overflow-hidden">
              <table class="table table-borderless align-middle m-0">
                <thead class="table-light">
                  <tr>
                    
                    <th scope="col">Archivo</th>
                    <th scope="col">Tipo</th>
                   
                  </tr>
                </thead>

                <tbody class="border-top-0">
                  <tr class="border-top" v-for="(d, i) in this.documento.data.adjuntos" :key="i">
                    <td scope="row" data-label="Descargar">
                      <button type="button" class="btn btn-sm btn-secondary" @click="descargarAdjunto2(d.idAdjunto, d.tipo, d.nombre)">
                        
                        <i class="bi bi-download"></i>
                      </button>
                    
                    &nbsp;
                      {{ d.nombre }}
                    </td>
                    <td scope="row" data-label="Tipo">
                      {{ d.tipo }}
                    </td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-2">
      <button type="button" class="btn btn-sm btn-primary" data-bs-toggle="tooltip" data-bs-placement="top"
        title="Responder documento" @click="responder()" v-if="buzon == 'Recibidos'">
        <i class="bi bi-file-earmark-plus me-1"></i>
        Responder
      </button>
      <button type="button" class="btn btn-sm btn-primary" @click="descargar">
        <i class="bi bi-file-earmark-arrow-down me-1"></i>
        Descargar documento
      </button>
    </div>

    <b-modal hide-footer id="VisualizarDocumentoModal" size="lg" :title="tituloModalRespuesta">
      <VisualizarDocumento :documento="documentoRespuesta" :buzon="buzon"
        @closeModal="$bvModal.hide('VisualizarDocumentoModal')" />
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");
import VisualizarDocumento from "./VisualizarDocumento.vue";

export default {
  name: "VisualizarDocumento",
  props: {
    ticket: Object,
    buzon: String,
  },
  data() {
    return {
      documentoRespuesta: null,
      tituloModalRespuesta: null,
      documento: null,
    };
  },
  components: {
    VisualizarDocumento,
  },
  validations: {
    modelo: {
      organismoOrigen: {},
      dependenciaOrigen: {},
      dependenciaOrigenCodigo: {},
      organismoDestino: {},
      dependenciaDestino: {},
    },
  },
  computed: {
    fechaLeida() {
      return moment(this.ticket.recibido).format("D MMM [de] YYYY [a las] LT");
    },
  },
  methods: {
    responder() {
      let docSeleccionado = this.ticket;

      if (docSeleccionado == undefined) {
        console.log("No se puede obtener el documento...");
      } else {
        this.$router.push({
          name: "EnviarDocumento",
          path: "/EnviarDocumento/",
          params: {
            guid: docSeleccionado.id,
            // nombre: docSeleccionado.documento.nombre,
            organismoOrigen:
              docSeleccionado.causaDestino.dependencia.codigoOrganismo,
            dependenciaOrigenCodigo:
              docSeleccionado.causaDestino.dependencia.codigoDependencia,
            dependenciaOrigen:
              docSeleccionado.causaDestino.dependencia.nombreDependencia,
            numeroOrigen: docSeleccionado.causaDestino.numero,
            caratulaOrigen: docSeleccionado.causaDestino.caratula,
            organismoDestino:
              docSeleccionado.causaOrigen.dependencia.codigoOrganismo,
            organismoDestinoNombre:
              docSeleccionado.causaOrigen.dependencia.nombreOrganismo,
            dependenciaDestino:
              docSeleccionado.causaOrigen.dependencia.codigoDependencia,
            dependenciaDestinoNombre:
              docSeleccionado.causaOrigen.dependencia.nombreDependencia,
            numeroDestino: docSeleccionado.causaOrigen.numero,
            caratulaDestino: docSeleccionado.causaOrigen.caratula,
          },
        });
      }
    },
    descargarAxios(mUrl, fileName){
      axios({
            url: mUrl, //your url
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    },
    descargar() {
      axios
        .get(`/api/Documentos/${this.ticket.id}/Link`)
        .then((response) => {
          var url = response.data.data.link;
          window.open(url, "_archivo");
        });
    },
    descargar2() {
      axios
        .get(`/api/Documentos/${this.ticket.id}/Link`)
        .then((response) => {
          var url = response.data.data.link;
          this.descargarAxios(url, `${this.ticket.id}.pdf` );
        });
    },
    descargarAdjunto(id, tipo, nombre) {
      let docId = this.ticket.id;
      nombre;
      axios
        .get(`/api/Adjuntos/Link?IdDocumento=${docId}&Id=${id}&Tipo=${tipo}`)
        .then((response) => {
          var url = response.data.data.link;
          window.open(url, "_archivo");
        });
    },
    descargarAdjunto2(id, tipo, nombre) {
      let docId = this.ticket.id;
      this.descargarAxios(`/api/Adjuntos/Descargar?IdDocumento=${docId}&Id=${id}&Tipo=${tipo}`, `${nombre}`);
    },
    formatear(fecha) {
      let f = moment(fecha).calendar();
      return f;
    },
    timeStamp(fecha) {
      let m = moment(fecha);
      let f = m.format("DD/MM/YYYY LT");
      return f;
    },
  },
  watch: {
    ticket: {
      immediate: true,
      deep: true,
      handler() {
        //buscar datos especificos de documento
        console.debug(this.ticket.tipoEnvio);

        if (this.ticket.tipoEnvio == "CON_ADJUNTOS") {
          axios.get(`/api/Documentos/${this.ticket.id}`).then((response) => {
            console.log(response.data);
            this.documento = response.data;
          });
        }
      }
    }
  },
};
</script>

<style>
.msgRechazado {
  font-size: 12px;
  font-style: oblique;
  color: #dc3545;
}

.spacing {
  margin-bottom: 0.2em;
}
</style>
