<template>
  <div>
      <Documentos />
  </div>
</template>

<script>
import Documentos from './Documentos/Documentos.vue';
export default {
  components: { Documentos },
  name: "Inicio",
  methods: {
    enviarNuevoDocumento() {
      this.$router.push({ path: "/EnviarDocumento/" });
    },
  },
};
</script>

<style></style>
